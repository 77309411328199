var render = function () {
  var _vm$airport, _vm$segment, _vm$airport2, _vm$segment2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center flex-column flex-1 align-items-center px-md-1 fw-700"
  }, [_c('div', {
    class: "font-weight-bolder d-flex flex-column flex-sm-col gap-1 align-items-center"
  }, [_c('div', {
    class: "text-warning text-center ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-5'),
    staticStyle: {
      "line-height": "1.2"
    }
  }, [_vm._v(" " + _vm._s(_vm.airportComputed ? _vm.airportComputed.city || _vm.airportComputed.name : '') + " ")]), _c('em', [_vm._v(" (" + _vm._s(_vm.airport.IATACode) + ") ")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }],
    staticClass: "text-nowrap",
    class: _vm.isMobileView ? 'font-medium-2' : 'font-large-1',
    attrs: {
      "title": "GMT ".concat(_vm.airport.timezone)
    }
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.airport.at, _vm.airport.timezone).time) + " ")]), _c('div', {
    staticClass: "text-nowrap font-weight-bolder",
    class: _vm.isMobileView ? 'font-small-4' : 'font-medium-3'
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.airport.at, _vm.airport.timezone).date) + " ")]), (_vm$airport = _vm.airport) !== null && _vm$airport !== void 0 && _vm$airport.terminal || (_vm$segment = _vm.segment) !== null && _vm$segment !== void 0 && _vm$segment.startTerminal ? _c('div', {
    staticClass: "text-nowrap d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/location-filled.svg"),
      "width": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.terminalNumber')) + " " + _vm._s(((_vm$airport2 = _vm.airport) === null || _vm$airport2 === void 0 ? void 0 : _vm$airport2.terminal) || ((_vm$segment2 = _vm.segment) === null || _vm$segment2 === void 0 ? void 0 : _vm$segment2.startTerminal)) + " ")], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }