<template>
  <div class="d-flex-center flex-column flex-1 align-items-center px-md-1 fw-700">
    <div :class="`font-weight-bolder d-flex flex-column flex-sm-col gap-1 align-items-center`">
      <div
        :class="`text-warning text-center ${isMobileView ? 'font-small-4' : 'font-medium-5'}`"
        style="line-height: 1.2"
      >
        {{ airportComputed ? airportComputed.city || airportComputed.name : '' }}
      </div>
      <em>
        ({{ airport.IATACode }})
      </em>
    </div>
    <div
      v-b-tooltip.hover.v-primary
      class="text-nowrap"
      :class="isMobileView ? 'font-medium-2' : 'font-large-1'"
      :title="`GMT ${airport.timezone}`"
    >
      {{ convertISODateTime(airport.at, airport.timezone).time }}
    </div>
    <div
      class="text-nowrap font-weight-bolder"
      :class="isMobileView ? 'font-small-4' : 'font-medium-3'"
    >
      {{ convertISODateTime(airport.at, airport.timezone).date }}
    </div>
    <div
      v-if="airport?.terminal || segment?.startTerminal"
      class="text-nowrap d-flex-center"
    >
      <b-img
        src="@icons/location-filled.svg"
        width="18"
        class="mr-25"
      />
      {{ $t('flight.terminalNumber') }} {{ airport?.terminal || segment?.startTerminal }}
    </div>
  </div>
</template>

<script>
import {
  VBTooltip, BImg,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store'

import { convertISODateTime } from '@core/utils/filter'

export default {
  components: {
    BImg,
  },
  directives: { 'b-tooltip': VBTooltip },
  props: {
    segment: {
      type: Object,
      default: () => {},
    },
    airport: {
      type: Object,
      default: () => {},
    },
    storeName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const getAirportByAirportCode = airportCode => store.getters[`${props.storeName}/getAirportByAirportCode`](airportCode)
    const airportComputed = computed(() => getAirportByAirportCode(props.airport?.IATACode))
    return {
      convertISODateTime,
      getAirportByAirportCode,
      airportComputed,
    }
  },
}
</script>
